export const contactsFetched = (contacts) => ({
  type: 'FETCH_CONTACTS_SUCCESS',
  contacts
});

export const fetchContacts = () => (dispatch) => { // (5)
  fetch("https://randomuser.me/api/?format=json&results=10")
    .then(res => res.json())
    .then(json => dispatch(contactsFetched(json.results)));
};

export const searchContacts = (text) => ({
  type: 'SEARCH_CONTACTS',
  text
});