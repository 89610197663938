import * as React from "react";
import { ContactsList } from "./ContactsList";
import { AppHeader } from "./AppHeader";
import { connect } from "react-redux";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { fetchContacts } from "../actions";
import { getFilteredContacts } from "../selectors/getFilteredContacts";
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom";
import Navbar from "./Navbar";
import MainPage from "./MainPage";
import SubPage from "./SubPage";
import LeftDrawer from "./LeftDrawer";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));



export default function App() {
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();



  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <Navbar open={open} setOpen={setOpen} classes={classes} />
        <LeftDrawer open={open} setOpen={setOpen} classes={classes} />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Switch>
            <Route exact path="/" component={MainPage} />
            <Route exact path="/sub" component={SubPage} />
          </Switch>
        </main>
      </div>
    </Router >
  )
};

const mapStateToProps = (state) => {
  return {
    contacts: getFilteredContacts(state.contacts, state.contactsSearch)
  }
};
const mapDispatchToProps = { fetchContacts };

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
